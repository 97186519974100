import { TieupPickupsGetInputModel } from "../../../api/v1/tieup/pickups/GetInputModel"
import { callGetTieupPickups } from "../../../api/v1/tieup/pickups/get"
import { WorkItem } from "../../WorkItem/WorkItem"

export const fetchPickups = (
  showR18: boolean,
  showR15 = true,
): Promise<WorkItem[]> => {
  const model = new TieupPickupsGetInputModel({
    showR15,
    showR18,
  })

  return callGetTieupPickups(model)
}
