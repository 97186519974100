import { Genre } from "../../../../../models/genre/Genre"
import { genreUrlPattern } from "./genreUrlPattern"

export const getGenreParams = (
  endpointUrl: string,
  userFavoriteGenre?: Genre[],
): string | undefined => {
  const results = endpointUrl.match(genreUrlPattern)

  if (!results) {
    return
  }

  if (!Array.isArray(userFavoriteGenre) || userFavoriteGenre.length === 0) {
    return
  }

  const [, idx, propName] = results

  const genre = userFavoriteGenre[parseInt(idx)]

  if (!genre) {
    return
  }

  if (propName !== "id" && propName !== "name") {
    return
  }

  return genre[propName] ? genre[propName].toString() : undefined
}
