import { DateTime, Interval } from "luxon"

// タイムゾーン指定なしのISO8601文字列をUTC時間として扱うため、unixTimeStampに一旦変換する
const getDateTimeObject = (iso: string): DateTime => {
  const unixTimeStamp = DateTime.fromISO(iso, { zone: "utc" }).toMillis()

  return DateTime.fromMillis(unixTimeStamp)
}

/**
 * タイムゾーンなしの `2000-11-11T11:00:00` のようなISO8601はUTC時間として扱われる。
 * ブラウザのタイムゾーンを期待しているばあいは正しい結果がえられないので注意。
 */
export default class TimeUtil {
  static formatTime(iso: string, format = "yyyy.MM.dd") {
    return getDateTimeObject(iso).toFormat(format)
  }

  /* 第1引数の日付が今日からN(第2引数)日以内か */
  static isWithinDays(iso: string, beforeDay: number) {
    const now = DateTime.local()
    const date = getDateTimeObject(iso)
    const interval = Interval.fromDateTimes(now.minus({ days: beforeDay }), now)

    return interval.contains(date)
  }

  /* 現在の日付との差分を取得 */
  static diffDateFromNow(iso: string) {
    const date = getDateTimeObject(iso)

    return {
      years: Math.round(Math.abs(date.diffNow("years").years)),
      months: Math.round(Math.abs(date.diffNow("months").months)),
      days: Math.round(Math.abs(date.diffNow("days").days)),
      hours: Math.round(Math.abs(date.diffNow("hours").hours)),
    }
  }
}
