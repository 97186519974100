import { Tieup } from "./Tieup"
import { TieupResponse } from "../../api/shared/models/TieupResponse"
import { adaptNovel } from "./novel/adaptNovel"

export const adaptTieup = (res: TieupResponse): Tieup => {
  return new Tieup({
    allReviewCount: res.all_review_count || 0,
    status: res.status,
    id: res.id,
    userId: res.user_id,
    appliedUserId: res.applied_user_id,
    novelId: res.novel_id,
    novel: adaptNovel(res.novel),
    baseNovel: res.base_novel ? adaptNovel(res.base_novel) : null,
    illustration: res.illustration || null,
    publicationsCount: res.publications_count || 0,
    createdAt: res.created_at,
    chatId: res.chat_id,
    appliedUser: res.applied_user,
    user: res.user,
    userRole: res.user_role,
    averageReviewScore: res.average_review_score || null,
    appliedUserRole: res.applied_user_role,
    wantToEliminatedByUser: res.want_to_eliminate_by_user,
    wantToEliminatedByAppliedUser: res.want_to_eliminate_by_user,
    message: res.message,
    reasonForElimination: res.reason_for_elimination || null,
    nextVolume: res.next_volume || null,
  })
}
