export const removeUndefinedParameter = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.entries(obj).reduce((output, [key, value]) => {
    if (Array.isArray(value)) {
      output[key] = value
        .filter((v) => v !== undefined)
        .map((v) => {
          if (typeof v === "object" && v !== null) {
            return removeUndefinedParameter(v)
          }

          return v
        })

      return output
    }

    if (value === undefined) {
      return output
    }

    if (typeof value === "object" && value !== null) {
      output[key] = removeUndefinedParameter(value as Record<string, unknown>)

      return output
    }

    output[key] = value

    return output
  }, {} as Record<string, unknown>)
}
