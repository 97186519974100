import React from "react"
import Link from "next/link"
import styles from "./index.module.scss"
import { Genre } from "src/models/genre/Genre"

type MenuType = "Header" | "Sidebar"

const makeSearchUrl = (genreId: number): string => {
  return `/tieups?g=${genreId}`
}

const GenreSearch: React.FC<{ genres?: Genre[]; type?: MenuType }> = ({
  genres,
  type = "Sidebar",
}) => {
  const isSidebar = type === "Sidebar"

  const classes = isSidebar
    ? {
        genreSearch: styles.genreSearch,
        list: styles.genreSearch_list,
        item: styles.genreSearch_item,
        itemAnchor: styles.genreSearch_item_anchor,
      }
    : {
        genreSearch: styles.headerGenreSearch,
        list: styles.headerGenreSearch_list,
        item: styles.headerGenreSearch_item,
        itemAnchor: styles.headerGenreSearch_item_anchor,
      }

  return (
    <div className={classes.genreSearch}>
      {isSidebar && <h3 className={styles.genreSearch_header}>作品を探す</h3>}
      {genres && (
        <ul className={classes.list}>
          {genres.map((genre) => (
            <li className={classes.item} key={genre.id}>
              <Link href={makeSearchUrl(genre.id)}>
                <a className={classes.itemAnchor}>{genre.name}</a>
              </Link>
            </li>
          ))}
        </ul>
      )}
      {isSidebar && (
        <Link href="/search/genres">
          <a className={styles.detailSearch}>
            詳細検索
            <i className={`c-arrow-small -colorRed`} />
          </a>
        </Link>
      )}
    </div>
  )
}

export default GenreSearch
