import { SlideJsonResponsive, isSlideJsonResponsive } from "types"
import { WorkItem } from "../models/WorkItem/WorkItem"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Pagination } from "swiper"
import { useEffect, useState, useRef, forwardRef, useContext } from "react"
import Head from "next/head"
import Link from "next/link"
import routes from "routes"
import { SliderConstant } from "src/config/sliderConstant"
import { globalStoreContext } from "src/store/GlobalStore"
import { AppImage } from "./common/AppImage"

// グランドトップの見出しのスライダー

type Props = {
  slides: SlideJsonResponsive[]
  pickups?: WorkItem[]
}

type MixSlide = SlideJsonResponsive | WorkItem

type SlideProps = {
  slide: MixSlide
}

const maxDisplaySlidesCount = 10

SwiperCore.use([Autoplay, Pagination])

const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

const HeroSlideShow: React.FC<Props> = ({ slides, pickups = [] }) => {
  const {
    globalStore: {
      loading: { fullScreenLoading },
    },
  } = useContext(globalStoreContext)

  const [swiper, updateSwiper] = useState<SwiperCore>()
  const [mixSlides, setMixSlides] = useState<MixSlide[]>([])
  const elm = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const shuffleContents = shuffle(pickups)
    const sliderContents = (slides as MixSlide[]).concat(shuffleContents)

    setMixSlides(sliderContents)
  }, [slides, pickups])

  useEffect(() => {
    if (fullScreenLoading) {
      // 全体ローディング中にスライドが流れてしまわないようにローディング中はスライダーを止める
      swiper?.autoplay.stop()
    }

    mixSlides.length && !fullScreenLoading && swiper?.autoplay.start()
  }, [swiper, fullScreenLoading, mixSlides.length])

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Head>
      {mixSlides.length && (
        <>
          <div className="top-heroSlider" data-cy="hero-slider">
            <div className="top-heroSlider-inner">
              <Swiper
                slidesPerView={"auto"}
                loopedSlides={maxDisplaySlidesCount}
                centeredSlides={true}
                spaceBetween={0}
                watchOverflow={true}
                loop
                navigation={true}
                onInit={(swiper: SwiperCore) => {
                  updateSwiper(swiper)
                }}
              >
                {mixSlides.map((slide, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <WrappedCreateSlideContent slide={slide} ref={elm} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const CreateSlideContent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  SlideProps
> = ({ slide }, ref) => {
  if (isSlideJsonResponsive(slide)) {
    const PictureElement: React.FC = () => (
      <picture ref={ref}>
        <source
          srcSet={slide.image_url_sp}
          media={`(max-width: ${SliderConstant.SpMaxWidth})`}
        />
        <img src={slide.image_url_pc} alt={slide.title} />
      </picture>
    )

    return !slide.link_url ? (
      <PictureElement />
    ) : (
      <div className="top-heroSlider-hero">
        <a
          href={slide.link_url}
          target={slide.is_blank ? "_blank" : "_self"}
          rel="noreferrer"
        >
          <PictureElement />
        </a>
      </div>
    )
  }

  return (
    <div className="top-heroSlider-novel" ref={ref}>
      <Link href={`${routes.seriesDetail(slide.novel_id.toString())}`}>
        <a className="top-heroSlider-novel-anchor">
          <div className="top-heroSlider-novel-coverImg">
            <AppImage
              src={
                slide.novel.sns_image ??
                slide.novel.cover_template_image ??
                "/img/cover.png"
              }
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
              alt={slide.novel.title}
            />
          </div>
          <div className="top-heroSlider-novel-textArea">
            <h3>{slide.novel.title}</h3>
          </div>
        </a>
      </Link>
    </div>
  )
}

const WrappedCreateSlideContent = forwardRef<HTMLDivElement, SlideProps>(
  CreateSlideContent,
)

export default HeroSlideShow
