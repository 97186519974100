import { Info } from "types"

export default class InfoUtil {
  // お知らせ一覧をHTMLからObjectへ変換、配列に追加して返す
  static parseHtml = (string: string): Info => {
    const tmp = []
    const parser = new DOMParser()
    const doc = parser.parseFromString(string, "text/html")
    const els = doc.getElementsByClassName(
      "info-card",
    ) as HTMLCollectionOf<HTMLElement>

    for (let i = 0; i < els.length - 1 && i < 3; i++) {
      const label = els[i]?.getElementsByClassName(
        "info-card_type-label",
      )[0] as HTMLElement
      const date = els[i]?.getElementsByClassName("info-card_date")[0]
        .children[0] as HTMLTimeElement
      const title = els[i]?.getElementsByClassName("info-card_title")[0]
        .children[0] as HTMLElement

      tmp[i] = {
        label: label.innerText,
        date: date.dateTime,
        title: title.innerText,
      }
    }

    return tmp
  }
}
