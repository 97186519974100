import { Tieup } from "./Tieup"
import { getWorkWorkId } from "../../api/v1/work/[work_id]/get"
import { WorkWorkIdGetInputModel } from "../../api/v1/work/[work_id]/GetInputModel"
import { adaptTieup } from "./adaptTieup"

export const fetchTieupForSlide = (tieupId: number): Promise<Tieup> => {
  return getWorkWorkId(new WorkWorkIdGetInputModel(tieupId)).then((res) =>
    adaptTieup(res),
  )
}
