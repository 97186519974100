import React, { useContext, useMemo } from "react"
import Link from "next/link"
import useSWR from "swr"
import { ColumnData } from "types"
import { WorkItem } from "../../../models/WorkItem/WorkItem"
import { shuffle } from "lodash"
import { globalStoreContext } from "src/store/GlobalStore"
import { BarLoader } from "react-spinners"
import colors from "../../../config/color"
import URLUtils from "src/utils/URLUtils"
import { fetchSlide } from "./Slider/lib/fetchSlide"
import { WorkItemHistory } from "../../../models/WorkItem/WorkItemHitosy"
import { Tieup } from "../../../models/tieup/Tieup"
import { fetchSlideFromJsonUrl } from "./Slider/lib/fetchSlideFromJsonUrl"
import {
  makeViewModelFromHistory,
  makeViewModelFromTieup,
  makeViewModelFromWorkItem,
} from "./Slider/SliderCard/makeViewModel"
import { useJudgeDevice } from "../../hooks/useJudgeDevice"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import { SliderCard } from "./Slider/SliderCard/SliderCard"
import { makeSliderUrl } from "./Slider/lib/makeSliderUrl"
import { genreUrlPattern } from "./Slider/lib/genreUrlPattern"
import { getGenreParams } from "./Slider/lib/getGenreParams"

SwiperCore.use([Navigation])

type Props = {
  columnData: ColumnData
  isR18: boolean
}

// リクエストのインターバル[msec]
const DEFAULT_INTERVAL = 2000
const slideLimit = 7

const SliderColumn: React.FC<Props> = ({ columnData, isR18 }) => {
  const {
    globalStore: {
      user: { apiUserData },
    },
  } = useContext(globalStoreContext)

  const { isLarge: isPc } = useJudgeDevice("md")
  const spaceBetween = isPc ? 20 : 16

  const sliderTitle = (() => {
    if (!columnData.title_label) {
      return null
    }

    if (!genreUrlPattern.test(columnData.title_label)) {
      return columnData.title_label
    }

    const genre = getGenreParams(
      columnData.title_label,
      apiUserData?.favorite_genres,
    )

    return genre ? columnData.title_label.replace(genreUrlPattern, genre) : null
  })()

  const sliderLink = (() => {
    if (!columnData.link || !columnData.link.transtion_url) {
      return null
    }

    const url = makeSliderUrl(
      columnData.link.transtion_url,
      isR18,
      apiUserData?.favorite_genres,
    )

    if (!url) {
      return null
    }

    return {
      url,
      label: columnData.link.label,
    }
  })()

  const fetcherUrl = useMemo(() => {
    if (columnData.endpoint) {
      return makeSliderUrl(
        columnData.endpoint,
        isR18,
        apiUserData?.favorite_genres,
      )
    }
    if (columnData.json) {
      return URLUtils.getStaticPage(columnData.json)
    }

    return null
  }, [columnData, isR18, apiUserData])

  const { data: sliderSource } = useSWR<
    WorkItem[] | WorkItemHistory[] | Tieup[]
  >(
    fetcherUrl,
    (url: string) => {
      if (columnData.endpoint) {
        return fetchSlide(url)
      }

      return fetchSlideFromJsonUrl(url)
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: !columnData.random, // ランダム表示時は再検証オプションを無効にする
      dedupingInterval: columnData.cache_interval
        ? columnData?.cache_interval
        : DEFAULT_INTERVAL,
    },
  )

  const viewModels = useMemo(() => {
    if (!sliderSource) {
      return
    }

    if (sliderSource.length === 0) {
      return
    }

    const vms = sliderSource.map((v, idx) => {
      if (v instanceof Tieup) {
        return makeViewModelFromTieup(v, columnData.display_ability)
      }

      if ((v as WorkItemHistory).work) {
        return makeViewModelFromHistory(v as WorkItemHistory)
      }

      return makeViewModelFromWorkItem(
        v as WorkItem,
        columnData.display_ability,
        idx,
      )
    })

    return columnData.random ? shuffle(vms) : vms
  }, [sliderSource, columnData.display_ability, columnData.random])

  // カラム、カラムのエンドポイント、見出しがnullの場合はコンポーネントを表示しない
  if ((!columnData.endpoint && !columnData.json) || !sliderTitle) return null
  // APIから取得した結果が0件だった場合もコンポーネントを表示しない
  if (sliderSource !== undefined && sliderSource.length === 0) return null

  // スライドの数を制限。フェッチ時のURLが設定ファイルで決まるためコード内でlimitを指定できない。
  // 設定ファイルで制限すべきだが、防御的にここでもここでもスライド数の制限を入れる。
  const targetSlides = viewModels ? viewModels.slice(0, slideLimit) : undefined

  return (
    <>
      <section className={`index-section --normal`} data-cy="slider-column">
        <header className="index-section-header">
          <div className="index-section-headerInner">
            <h2 className="index-section-title">{sliderTitle}</h2>
            {sliderLink && (
              <Link href={sliderLink.url}>
                <a className="index-section-anchor">
                  <p className="index-section-listLinkText">
                    <span>{sliderLink.label}</span>
                    <i className="c-arrow-small -colorRed" />
                  </p>
                </a>
              </Link>
            )}
          </div>
        </header>
        {targetSlides ? (
          <div className="index-section-sliderBody">
            <Swiper
              spaceBetween={spaceBetween}
              slidesPerView="auto"
              navigation
              className="index-section-sliderContainer"
            >
              {targetSlides.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <SliderCard viewModel={item} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        ) : (
          <div className="index-section-loader">
            <BarLoader color={colors.spectrum.tieupTheme} />
          </div>
        )}
      </section>
    </>
  )
}

export default SliderColumn
