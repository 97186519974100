import { Info } from "types"
import TimeUtil from "src/utils/TimeUtil"
import URLUtils from "src/utils/URLUtils"

type props = {
  info: Info
}

/** お知らせ */
const Information: React.FC<props> = ({ info }) => {
  return (
    <div className="index-section">
      <header className="index-section-header">
        <div className="index-section-headerInner">
          <h2 className="index-section-title">お知らせ</h2>
          <a
            className="index-section-anchor"
            href={`${URLUtils.getHelpPage("info/index.html")}`}
            target="_blank"
            rel="noreferrer"
          >
            <p className="index-section-listLinkText">
              <span>もっと見る</span>
              <i className="c-arrow -colorRed" />
            </p>
          </a>
        </div>
      </header>

      <ul className="index-section-info">
        {info.map((info, i) => {
          return (
            <li key={i} className="index-section-info-item">
              <a
                href={`${URLUtils.getHelpPage("info/index.html")}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="index-section-info-labels">
                  <time dateTime={TimeUtil.formatTime(info.date, "yyyy/MM/dd")}>
                    {TimeUtil.formatTime(info.date, "yyyy/MM/dd")}
                  </time>
                  <p>{info.label}</p>
                </div>
                <h3>{info.title}</h3>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Information
