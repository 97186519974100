import URLUtils from "src/utils/URLUtils"
import styles from "./index.module.scss"

const CreatorTieupNovelsBanner: React.FC = () => {
  return (
    <a
      href={URLUtils.getCreatorTieupNovelsURL()}
      rel="noreferrer noopener"
      target="_blank"
      className={styles.banner}
    >
      <picture>
        <source srcSet="/img/bnr_creator_pc.png" media={`(min-width: 480px)`} />
        <img src="/img/bnr_creator_sp.jpg" alt="クリエイターの方はこちら" />
      </picture>
    </a>
  )
}

export default CreatorTieupNovelsBanner
