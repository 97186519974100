import { BaseGetInputModel } from "../../../../models/http/InputModel/BaseGetInputModel"

export class WorkWorkIdGetInputModel extends BaseGetInputModel {
  constructor(private tieupId: number) {
    super()
  }

  get pathParams() {
    return {
      // work_id = tieup_idなのでtieup_idを指定する
      work_id: this.tieupId.toString(),
    }
  }
}
