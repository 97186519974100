import React from "react"
import Link from "next/link"
import { AppImage } from "../../../../common/AppImage"
import { SliderCardViewModel } from "./ViewModel"

type Props = {
  viewModel: SliderCardViewModel
}

export const SliderCard: React.FC<Props> = ({ viewModel }) => {
  return (
    <Link href={viewModel.linkUrl}>
      <a target={viewModel.target} className="index-works-item-a">
        <article className={`index-works-item js-worksCarousel-item`}>
          <div className="index-works-image">
            <AppImage
              src={viewModel.imageUrl || "/img/cover.png"}
              alt={viewModel.title}
              width="139px"
              height="201px"
              objectFit="cover"
            />
          </div>
          <div className="index-works-detail">
            {viewModel.ranking && (
              <p
                className={`index-works-rankLabel --number${viewModel.ranking}`}
              >
                {viewModel.ranking}位
              </p>
            )}
            {viewModel.isNew && (
              <span className="index-works-newLabel">NEW!</span>
            )}
            {viewModel.title && (
              <h3 className={`index-works-title --normal`}>
                {viewModel.title}
              </h3>
            )}
            {viewModel.catchCopy && (
              <p className="index-works-copy">{viewModel.catchCopy}</p>
            )}
          </div>
        </article>
      </a>
    </Link>
  )
}
