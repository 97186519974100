import { ApiUserData } from "src/models/user/ApiUserData"
import { Novel } from "src/models/tieup/novel/Novel"
import { TieupRequestStatus } from "./TieupRequestStatus"
import { IllustrationData } from "./illustration/IllustrationData"
import { Chapter } from "./novel/Chapter"
import { removeUndefinedParameter } from "../../libs/removeUndefinedParameter"

export type TieupValue = {
  allReviewCount: number
  status: TieupRequestStatus
  id: number
  userId: string
  appliedUserId: string
  novelId: number
  novel: Novel
  baseNovel: Novel | null
  illustration: IllustrationData | null
  publicationsCount: number
  createdAt: string
  chatId: number
  appliedUser: ApiUserData
  user: ApiUserData
  userRole: number
  appliedUserRole: number
  averageReviewScore: number | null
  wantToEliminatedByUser: boolean
  wantToEliminatedByAppliedUser: boolean
  message: string
  reasonForElimination: string | null
  nextVolume: number | null
}

export class Tieup {
  constructor(private value: TieupValue) {
    // noop
  }

  get allReviewCount(): number {
    return this.value.allReviewCount
  }

  get status(): TieupRequestStatus {
    return this.value.status
  }

  get id(): number {
    return this.value.id
  }

  get userId(): string {
    return this.value.userId
  }

  get appliedUserId(): string {
    return this.value.appliedUserId
  }

  get novelId(): number {
    return this.value.novelId
  }

  get novel(): Novel {
    return this.value.novel
  }

  get baseNovel(): Novel | null {
    return this.value.baseNovel
  }

  get baseNovelId(): number | null {
    return this.baseNovel ? this.baseNovel.id : null
  }

  get illustration(): IllustrationData | null {
    return this.value.illustration
  }

  get publicationsCount(): number {
    return this.value.publicationsCount
  }

  get createdAt(): string {
    return this.value.createdAt
  }

  get chatId(): number {
    return this.value.chatId
  }

  get user(): ApiUserData {
    return this.value.user
  }

  get userRole(): number {
    return this.value.userRole
  }

  get appliedUser(): ApiUserData {
    return this.value.appliedUser
  }

  get appLiedUserRole(): number {
    return this.value.appliedUserRole
  }

  get wantToEliminatedByUser(): boolean {
    return this.value.wantToEliminatedByUser
  }

  get wantToEliminatedByAppliedUser(): boolean {
    return this.value.wantToEliminatedByAppliedUser
  }

  get message(): string {
    return this.value.message
  }

  get reasonForElimination(): string | null {
    return this.value.reasonForElimination
  }

  get nextVolume(): number | null {
    return this.value.nextVolume
  }

  // @todo Novelクラス作ってそっちに移動する方がいいかもしれない
  get sortedChapters(): Chapter[] {
    if (!this.novel.chapters) {
      return []
    }

    return this.novel.chapters
      .map((v) => v)
      .sort((v1, v2) => {
        if (v1.sort_order < v2.sort_order) return -1
        if (v1.sort_order > v2.sort_order) return 1

        return 0
      })
  }

  collaborator(target: ApiUserData): ApiUserData {
    if (this.userId === target.id) {
      return this.value.appliedUser
    }

    return this.value.user
  }

  novelist(): ApiUserData {
    return this.userRole === 1 ? this.user : this.value.appliedUser
  }

  illustrator(): ApiUserData {
    return this.userRole === 2 ? this.user : this.value.appliedUser
  }

  hasReview(): boolean {
    return this.value.allReviewCount > 0
  }

  cellingAverageReviewScore(): number {
    if (!this.value.averageReviewScore) return 0

    // 0.5 単位で丸めて切り上げ
    return Math.ceil(this.value.averageReviewScore * 2) / 2
  }

  serialize(): TieupValue {
    return removeUndefinedParameter(this.value) as TieupValue
  }
}
