import { useLocalStorage } from "react-use"
import { useCommonConfirmDialog } from "src/components/hooks/useCommonConfirmDialog"

export const useToggleR18 = () => {
  const [showR18, setShowR18] = useLocalStorage<boolean>("show_r18", false)

  const [isOver18, setIsOver18] = useLocalStorage<boolean>("over_18", false)

  const { confirmDialog } = useCommonConfirmDialog()

  /** 18禁コンテンツの表示 / 非表示 */
  const toggleShowR18 = (callback?: () => void) => {
    const execCallback = () => {
      if (callback) {
        callback()
      }
    }
    if (!showR18 && !isOver18) {
      confirmDialog("あなたは18歳以上ですか？", "はい", "いいえ", () => {
        setShowR18(!showR18)
        setIsOver18(true)
        execCallback()
      })
    } else {
      setShowR18(!showR18)
      execCallback()
    }
  }

  return { showR18, toggleShowR18 }
}
