import { NovelResponse } from "../../../api/shared/models/NovelResponse"
import { Novel } from "./Novel"

export const adaptNovel = (res: NovelResponse): Novel => {
  return {
    id: res.id,
    title: res.title,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    catchCopy: res.catch_copy,
    outline: res.outline_text,
    turns: res.turns,
    originalGenres: res.original_genres,
    chapters: res.chapters,
    isR15: res.is_r15,
    isR18: res.is_r18,
    isGrotesque: res.is_grotesque,
    isViolence: res.is_violence,
    isSexual: res.is_sexual,
    snsImage: res.sns_image,
    snsTitle: res.sns_title,
    snsIntroduction: res.sns_introduction,
    workId: res.work_id,
    status: res.status,
    hashtags: res.hashtags,
    genres: res.genres,
    notes: res.notes,
    novelSeriesId: res.novel_series_id,
    isPaid: res.is_paid,
    coverImage: res.cover_template_image,
    userId: res.user_id,
    isTieup: res.is_tieup,
    isPublished: res.is_published,
    readersNumber: res.readers_number,
    anotherSiteUrl: res.another_site_url,
    publishedAt: res.published_at,
    user: res.user,
    frontispieces: res.frontispieces,
  }
}
