import { genreUrlPattern } from "./genreUrlPattern"
import { Genre } from "../../../../../models/genre/Genre"
import { getGenreParams } from "./getGenreParams"

export const makeSliderUrl = (
  endpointSource: string,
  isR18: boolean,
  userFavoriteGenre?: Genre[],
): string | null => {
  const adaptedR18 = endpointSource.replace(/{is_r18}/i, isR18 ? "yes" : "no")

  // ユーザーのお気に入りジャンル指定を含まないURLはそのまま返す
  if (!genreUrlPattern.test(adaptedR18)) {
    return adaptedR18
  }

  const genre = getGenreParams(adaptedR18, userFavoriteGenre)

  // パターンにマッチするお気に入りジャンルがない場合はnullを返す
  if (!genre) {
    return null
  }

  return adaptedR18.replace(genreUrlPattern, genre)
}
