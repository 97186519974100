import React, { useCallback, useMemo, useRef, useState } from "react"
import { useMount } from "react-use"
import { DefaultLayoutForReader } from "src/components/common/layouts/DefaultLayoutForReader"
import HTMLHead from "src/components/utils/HTMLHead"
import axios from "axios"
import useSWR from "swr"
import cacheData from "memory-cache"
import getConfig from "next/config"
import URLUtils from "src/utils/URLUtils"

import { ColumnData, Info, SlideJsonResponsive } from "types"
import { WorkItem } from "../models/WorkItem/WorkItem"
import styles from "./index.module.scss"
import HeroSlideShow from "src/components/HeroSlideShow"
import SliderColumn from "src/components/pages/home/SliderColumn"
import Information from "src/components/pages/home/Information"
import ToggleButton from "src/components/pages/home/ToggleButton"
import { useUser } from "src/utils/auth/useUser"
import { http } from "src/services/http"
import { useToggleR18 } from "src/components/hooks/useToggleR18"
import InfoUtil from "src/utils/InfoUtil"
import GenreSearch from "../components/pages/home/GenreSearch"
import CreatorTieupNovelsBanner from "../components/pages/home/CreatorTieupNovelsBanner"
import { GetServerSideProps } from "next"
import { fetchPickups } from "../models/tieup/pickups/fetchPickups"
import { fetchGenres } from "../models/genre/fetchGenres"

type Props = {
  pickupsSSR: WorkItem[]
  heroSlides: SlideJsonResponsive[]
  columnDataLoggedin: ColumnData[]
  columnDataGuest: ColumnData[]
  infoSSR: string
}

// キャッシュのインターバル[msec]
const HOURS = 3
const CACHE_INTERVAL = 1000 * 60 * 60 * HOURS

const Home: React.FC<Props> = ({
  pickupsSSR,
  heroSlides,
  columnDataLoggedin,
  columnDataGuest,
  infoSSR,
}) => {
  const { showR18, toggleShowR18 } = useToggleR18()
  const { isMember } = useUser()

  const currentColumnData = useMemo(() => {
    return isMember ? columnDataLoggedin : columnDataGuest
  }, [isMember, columnDataLoggedin, columnDataGuest])

  const initDraw = useRef(true)

  /** 18禁コンテンツの表示 / 非表示 */
  const handleChangeR18Toggle = useCallback(() => {
    toggleShowR18(() => {
      if (initDraw.current) initDraw.current = false
    })
  }, [toggleShowR18, initDraw])

  const { data: pickups } = useSWR(
    `/v1/tieup/pickups?is_r15=yes&is_r18=${showR18}`,
    () => fetchPickups(showR18 || false),
    {
      fallbackData: pickupsSSR,
      revalidateOnFocus: false,
      revalidateOnMount: !initDraw.current,
    },
  )

  const [info, setInfo] = useState<Info>([])

  const { data: genres, error } = useSWR("/v1/genre", fetchGenres)
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  useMount(() => {
    setInfo(InfoUtil.parseHtml(infoSSR))
  })

  return (
    <DefaultLayoutForReader>
      <HTMLHead path="/" />
      <main>
        <div className={styles.headerMenu}>
          <GenreSearch genres={genres} type="Header" />
        </div>
        {/* カルーセル */}
        {heroSlides.length > 0 && (
          <HeroSlideShow slides={heroSlides} pickups={pickups} />
        )}

        {/* 18禁表示トグル */}
        <ToggleButton
          isChecked={!!showR18}
          handleChangeToggle={handleChangeR18Toggle}
        />
        <div className={styles.banner_sp}>
          <CreatorTieupNovelsBanner />
        </div>

        <section className={styles.mainContent}>
          <div className={styles.mainContent_Menu}>
            <div className={styles.banner_pc}>
              <CreatorTieupNovelsBanner />
            </div>
            <GenreSearch genres={genres} />
          </div>
          <div className={styles.mainContent_Body}>
            {
              /* スライダーカラム  */
              currentColumnData?.length > 0 &&
                currentColumnData.map((columnData, index) => (
                  <SliderColumn
                    key={index}
                    columnData={columnData}
                    isR18={!!showR18}
                  />
                ))
            }
            {/* お知らせ */}
            {info.length > 0 && <Information info={info} />}
          </div>
        </section>
      </main>
    </DefaultLayoutForReader>
  )
}

export default Home
type CachedData<T> = {
  data: Array<T>
  isCached: boolean
}
/** キャッシュ動作付きフェッチ */
async function fetchWithCache<T>(url: string): Promise<CachedData<T>> {
  // 有効期限が切れていたら null が返ってくる
  const cachedResponse: Array<T> = cacheData.get(url)
  if (cachedResponse) {
    return { data: cachedResponse, isCached: true }
  } else {
    const res = await http.get(url)
    const data: Array<T> = await res.data
    cacheData.put(url, data, CACHE_INTERVAL)

    return { data: data, isCached: false }
  }
}

/* フェッチしてレスポンスからデータを取り出して返す */
async function extractDataFromFetch<T>(
  path: string,
  isFetchWithCache = false,
): Promise<T> {
  try {
    let res
    // isFetchWithCacheがtrueならキャッシュ動作付きフェッチをつかう
    if (isFetchWithCache) {
      res = await fetchWithCache<T>(path)
    } else {
      res = await axios.get(path)
    }

    return res.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`>>>>>>>>>> エラー ${path} >>>>>>>>>>`)
    // eslint-disable-next-line no-console
    console.log(error)

    throw new Error(error)
  }
}

export const getServerSideProps: GetServerSideProps = async () => {
  // getConfig() 経由で読み込まないとビルド時にエラーが起きる
  // @see: https://app.circleci.com/pipelines/github/trenders/prj-tieup-front/3567/workflows/8fcbf60a-7e8c-4609-b492-763c5abfad8f/jobs/3963?invite=true#step-137-1112
  const { serverRuntimeConfig } = getConfig()

  /** Hero */
  const heroSlides = await extractDataFromFetch<SlideJsonResponsive[]>(
    `${URLUtils.getStaticPage("parts/top_slide.json")}`,
  )

  const pickupsSSR = await extractDataFromFetch<WorkItem[]>(
    `${serverRuntimeConfig.API_URL}/v1/tieup/pickups?is_r15=yes&is_r18=no`,
    true,
  ).catch(() => [])

  const columnDataLoggedin = await extractDataFromFetch<ColumnData[]>(
    `${URLUtils.getStaticPage("parts/top_columns_loggedin.json")}`,
  ).catch(() => [])

  const columnDataGuest = await extractDataFromFetch<ColumnData[]>(
    `${URLUtils.getStaticPage("parts/top_columns_guest.json")}`,
  ).catch(() => [])

  const infoSSR = await extractDataFromFetch<string>(
    `${URLUtils.getHelpPage("info/index.html")}`,
  ).catch(() => [])

  return {
    props: {
      pickupsSSR,
      heroSlides,
      columnDataLoggedin,
      columnDataGuest,
      infoSSR,
    },
  }
}
