import { BaseGetInputModel } from "../../../../models/http/InputModel/BaseGetInputModel"

type TieupPickupsGetParams = {
  showR18: boolean
  showR15: boolean
}

export class TieupPickupsGetInputModel extends BaseGetInputModel {
  constructor(private params: TieupPickupsGetParams) {
    super()
  }

  get query(): URLSearchParams {
    return new URLSearchParams({
      is_r18: this.params.showR18 ? "yes" : "no",
      is_r15: this.params.showR15 ? "yes" : "no",
    })
  }
}
