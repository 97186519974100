// @todo https://github.com/trenders/prj-tieup-front/issues/2121 content, review_rateの型付
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Genre } from "src/models/genre/Genre"
import { ApiUserData } from "src/models/user/ApiUserData"

/** 型情報 */

const providers = ["google.com", "twitter.com", "password"] as const
export type Providers = typeof providers[number]

/** Firebaseユーザーデータ */
export type FirebaseUserData = {
  /** Firebase UID */
  id: string
  /** メールアドレス */
  email: string | null
  photoURL: string | null
  displayName: string | null
  providers: readonly Providers[] | null
}

/** 空のユーザーデータ */
export type EmptyUserData = {
  id: null
  email: null
  photoURL: null
  displayName: null
  providers: null
}

/** APIユーザーデータ */
export type UserData = {
  status: number
  id: string
  /** ユーザーID */
  display_name: string
  /** ユーザー名 */
  name: string
  /** 誕生日 */
  birthdate: string
  /** アイコンURL */
  icon_url: string
  /** 自己紹介 */
  profile: string
  /** Twitterアカウント名 */
  twitter_url: string
  /** Youtube URL */
  youtube_channel_url: string
  /** インスタグラムアカウント名 */
  instagram_url: string
  /** TikTokアカウント名 */
  tiktok_url: string
  /** ジャンル */
  favorite_genres?: Genre[]
  mail_address: string
}

/** ユーザーステート
 * Firebase 未ログイン: 0
 * Firebaseログイン済みで未登録のゲストユーザー: 1
 * メンバー: 2
 */
export type ClientUserStateNumber = 0 | 1 | 2
export type ClientUserState = ClientUserStateNumber | null

/**
 * 小説公開ステータス
 * 現状では0, 7の値しか使っていない
 */
export enum NovelStatus {
  DRAFT, // 未公開
  REQUEST_REVIEW, // 公開申請, 再申請
  UNDER_REVIEW, // 校閲中
  SEND_BACKED, // 差戻し
  AVAILABLE_FOR_SALE,
  SALE, // 商品登録完了
  END_SALE, // 有料公開完了
  RELEASE_FOR_FREE, // 公開（処理完了）、無料作品も有料作品も公開中はこのステータス
}

/** 章データ */
export type ChapterData = {
  id: number
  novel_id: number
  title: string
  is_afterward: boolean
  sort_order: number
  word_count: number
  created_at: string
  updated_at: string
  is_published: boolean
  content: string
}

/**
 * Viewer 関連
 */
export type PageData = {
  rightShift: number
  data: Array<string | ParagraphData>
  beginIndex: number
  endIndex: number
  rest: number
  image?: string
  width: number
  key: string
}

export type PageInformation = {
  maxWidth: number
  maxRows: number
  width: number
  padding: number
  lineHeight: number
  contentWidth: number
}

export type ViewerLines = Array<string | ParagraphData>

export type ViewerData = {
  // 段落データ
  lines: ViewerLines
  meta: ViewerMetaData
}
export type ViewerMetaData = {
  // 話id
  chapter_id?: string | null // 新規作成の chapter の場合 id は持っていない
  // 登録日時
  created_at?: string
  // 最終更新日時
  updated_at?: string
  // 話タイトル
  title: string
  // 並び順値
  order?: number
  // 画像
  images: Array<ImageData> | null
}

export type ParagraphData = {
  texts: Array<string | SpanData>
  meta?: Array<ParagraphMetaData>
}
export type SpanData = {
  text: string
  meta?: Array<SpanMetaData>
}

export type ImageData = {
  id: string
  src: string
}

export type StartImage = { type: "start-image"; id: string }
export type EndImage = { type: "end-image"; id: string }
export type ParagraphMetaData = StartImage | EndImage

export type Ruby = { type: "ruby"; value: string }
export type EXCLAMATION = { type: "exclamation"; value: string }
export type Dot = { type: "dot" }
export type SpanMetaData = Ruby | Dot | EXCLAMATION

export enum ViewerBackgroundType {
  White = "white",
  Black = "black",
  Cream = "cream",
}

export enum ViewerFontType {
  Mincho = "mincho",
  Gothic = "gothic",
}

export enum ViewerFontSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/** 図版オブジェクト */
export type PlateData = {
  created_at: string
  id: number
  novel_id: number
  status: boolean
  title: string
  updated_at: string
  url: string | undefined
  user_id: string
  chapter_id: number
}

/** クリエイターユーザーページのProps */
export type CreatorUserPageProps = {
  userId: string
}

/** 画像トリミングダイヤログ */
export type Area = {
  width: number
  height: number
  x: number
  y: number
}

/**
 * ページングラッパー
 * @deprecated model/pagingを利用. APIのレスポンス定義は api/shared/models/PagingResponse.ts を利用
 */
export type Paging<T> = {
  count: number
  next: boolean
  prev: boolean
  results: Array<T>
}

export type FCMMessage = {
  data: { time: string; score: string }
  from: string
  notification: {
    title: string
    body: string
  }
  priority: string
}

export type SlideJson = {
  image_url: string
  title: string
  link_url?: string
  is_blank?: boolean
}

export type SlideJsonResponsive = {
  image_url_pc: string
  image_url_sp: string
  title: string
  link_url?: string
  is_blank?: boolean
}

export const isSlideJsonResponsive = (
  arg: unknown,
): arg is SlideJsonResponsive =>
  typeof arg === "object" &&
  arg !== null &&
  typeof (arg as SlideJsonResponsive).image_url_pc === "string" &&
  typeof (arg as SlideJsonResponsive).image_url_sp === "string" &&
  typeof (arg as SlideJsonResponsive).title === "string" &&
  (typeof (arg as SlideJsonResponsive).link_url === "string" ||
    typeof (arg as SlideJsonResponsive).link_url === "undefined") &&
  (typeof (arg as SlideJsonResponsive).is_blank === "boolean" ||
    typeof (arg as SlideJsonResponsive).is_blank === "undefined")

// トップ、クリエイタートップのお知らせ一覧
export type Info = {
  label: string
  date: string
  title: string
}[]

// トップ、jsonから取得するスライダーカラムのデータ
export type ColumnData = {
  endpoint?: string | null
  json?: string | null
  title_label: string | null
  display_ability: DisplayAbility
  link?: {
    label: string
    transtion_url: string | null
  }
  cache_interval?: number
  random?: boolean
}

export type DisplayAbility = {
  new: boolean
  title: boolean
  catchcopy: boolean
  ranking: boolean
}

/* 購入状態 */
export type PurchaseData = {
  id: number
  tieup_id: number
  is_purchased: boolean
  purchased_date: string
  price: number
  tax: number
  created_at: string
  created_user: ApiUserData
  updated_at: string
  updated_user: ApiUserData
}

/* 購入履歴 */
export type PurchasedHistory = {
  id: number
  purchased_date: string
}
