import React from "react"

type Props = {
  isChecked: boolean
  handleChangeToggle: () => void
}

const ToggleButton: React.FC<Props> = ({ isChecked, handleChangeToggle }) => {
  return (
    <div className="index-section-r18switch">
      <p>18禁作品の表示</p>
      <label className="index-section-toggle">
        <input
          className="index-section-toggle-input"
          type="checkbox"
          name="r18toggle"
          value="r18toggle"
          onChange={handleChangeToggle}
          checked={isChecked}
        />
        <span className="index-section-toggle-button">
          <i className="fas fa-check index-section-toggle-checkIcon"></i>
          <i className="fas fa-times index-section-toggle-timesIcon"></i>
        </span>
      </label>
    </div>
  )
}

export default ToggleButton
