import { DisplayAbility } from "../../../../../../types"
import { WorkItem } from "../../../../../models/WorkItem/WorkItem"
import { SliderCardViewModel } from "./ViewModel"
import routes from "../../../../../../routes"
import TimeUtil from "../../../../../utils/TimeUtil"
import { WorkItemHistory } from "../../../../../models/WorkItem/WorkItemHitosy"
import { Tieup } from "../../../../../models/tieup/Tieup"

export const makeViewModelFromWorkItem = (
  item: WorkItem,
  displayAbility: DisplayAbility,
  idx: number,
): SliderCardViewModel => {
  return {
    id: item.id,
    linkUrl: routes.seriesDetail(item.novel.id.toString()),
    target: "_self",
    imageUrl: item.novel.cover_template_image,
    imageAlt: item.novel.title,
    title: displayAbility.title ? item.novel.title : undefined,
    ranking: displayAbility.ranking ? idx + 1 : undefined,
    /* 公開されてから2週間以内の作品か*/
    isNew:
      displayAbility.new &&
      !!item.novel.published_at &&
      TimeUtil.isWithinDays(item.novel.published_at, 14),
    catchCopy: displayAbility.catchcopy ? item.novel.catch_copy : undefined,
  }
}

// @todo リンクの修正。もしかしたら履歴取得から変更する必要があるかも。
export const makeViewModelFromHistory = (
  item: WorkItemHistory,
): SliderCardViewModel => {
  return {
    id: item.work.id,
    linkUrl: routes.seriesDetail(item.work.novel.id.toString()),
    target: "_blank",
    imageUrl: item.work.novel.cover_template_image,
    imageAlt: item.work.novel.title,
    title: item.work.novel.title,
    catchCopy: item.work.novel.catch_copy,
  }
}

export const makeViewModelFromTieup = (
  item: Tieup,
  displayAbility: DisplayAbility,
) => {
  return {
    id: item.id,
    linkUrl: routes.seriesDetail(item.novelId.toString()),
    target: "_self",
    imageUrl: item.novel.coverImage,
    imageAlt: item.novel.title,
    title: displayAbility.title ? item.novel.title : undefined,
    /* 公開されてから2週間以内の作品か*/
    isNew:
      displayAbility.new &&
      !!item.novel.publishedAt &&
      TimeUtil.isWithinDays(item.novel.publishedAt, 14),
    catchCopy: displayAbility.catchcopy ? item.novel.catchCopy : undefined,
  }
}
