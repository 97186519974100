import Image, { ImageLoader, ImageProps } from "next/image"

type Props = Omit<ImageProps, "src"> & {
  src: string
}

const loader: ImageLoader = ({ src, width, quality }) => {
  if (/^blob/.test(src) || /^http/.test(src)) {
    return src
  }

  if (
    process.env.NEXT_PUBLIC_IMAGE_ASSET_URL &&
    /imgix\.net/.test(process.env.NEXT_PUBLIC_IMAGE_ASSET_URL)
  ) {
    const q = quality || 75

    return `${process.env.NEXT_PUBLIC_IMAGE_ASSET_URL}${src}?w=${width}&q=${q}&auto=format`
  }

  const schemaAndHost =
    process.env.NEXT_PUBLIC_IMAGE_ASSET_URL || process.env.NEXT_PUBLIC_S3_URL

  return `${schemaAndHost}${src}`
}

export const AppImage = (props: Props): JSX.Element => {
  if (!props.src) {
    return <></>
  }

  return <Image {...props} loader={loader} />
}
