import { Tieup } from "../../../../../models/tieup/Tieup"
import { fetchTieupForSlide } from "../../../../../models/tieup/fetchTieupForSlide"
import axios from "axios"

export const fetchSlideFromJsonUrl = async (
  jsonUrl: string,
): Promise<Tieup[]> => {
  const response = await axios.get<{ id: number }[]>(jsonUrl).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error)
  })

  const tieupIds = response?.data

  if (!tieupIds) return []

  const promises = tieupIds.map(({ id }) => fetchTieupForSlide(id))

  return await Promise.all(promises)
}
