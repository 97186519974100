import { httpGet } from "../../../../../libs/http/httpGet"
import { WorkItem } from "../../../../../models/WorkItem/WorkItem"
import { WorkItemHistory } from "../../../../../models/WorkItem/WorkItemHitosy"
import { DynamicGetInputModel } from "../../../../../models/http/InputModel/DynamiGetInputModel"

type Response =
  | {
      results: WorkItem[] | WorkItemHistory[]
    }
  | WorkItem[]
  | WorkItemHistory[]

export const fetchSlide = (
  endpointUrl: string,
): Promise<WorkItem[] | WorkItemHistory[]> => {
  return httpGet<Response>(endpointUrl, new DynamicGetInputModel()).then(
    (res) => {
      if (Array.isArray(res)) {
        return res
      }

      return res.results
    },
  )
}
